

$(document).ready(function() {
    $('.multiSelect').SumoSelect();

    $('#type_prospections_id').change(function(){
        //Clusteur ext
        if($(this).val() == 3){
            $('.clusteur_in').hide();
        }
        else{
            $('.clusteur_in').show();
        }
    });

    showSellerTable();
    hideExistSeller();

    $(document).on("change", "#rdv_date", function() {
        checkAvailabilitySeller();
    });

    $(document).on("change", "#change_rdv_date", function() {
        checkAvailabilitySeller();
    });

    $(document).on("click", "#change_rdv_date", function() {
    });

    $(document).on('hidden.daterangepicker', function(ev, picker) {
     
    });




    $(document).on("change", "#rdv_heure", function() {
        checkAvailabilitySeller();
    });
    

    $(document).on("change", "#change_rdv_heure", function() {
        checkAvailabilitySeller();
    });

});

function SortByName(x,y) {
    return ((x.name == y.name) ? 0 : ((x.name > y.name) ? 1 : -1 ));
}

function RoleChange() {
    var val = $('#role').val(); // on récupère la valeur du type de groupe
    if (val != 0) {
        $('#structure').empty(); // on vide la liste structure parent

        $.ajax({
            type: 'GET',
            url: '/structure/GetLstStructure',
            data: 'id_groupe=' + val, // on envoie $_GET['id_groupe']
            dataType: 'json',
            success: function (json) {
                json.sort(SortByName);

                $.each(json, function (index, value) {
                    $('#structure').append('<option value="' + value["id"] + '">' + value["name"] + '</option>');
                });

                $('#structure').prop('disabled', false);
            }
        });
    } else {
        $('#structure').val(0);
        $('#structure').prop('disabled', true);
    }

    
    
}


function add_row()
{
    var IdRole = $('#role').val();
    var LibRole = $('#role option:selected').text();
    var IdStructure = $('#structure').val();
    var LibStructure = $('#structure option:selected').text();
    var bManager = ($('#manager')[0].checked ? 1 : 0);
    var date_deb = $('#date_deb').val();
    var date_fin = $('#date_fin').val();

    if(IdStructure > 0) {
        var table = document.getElementById("lstUserStructure");
        var table_len = (table.rows.length) - 1;
        var row = table.insertRow(table_len).outerHTML = "<tr id='row" + IdStructure + "' class='item'>" +
            "<td class='uk-text-center'><input id='IdRole" + IdStructure + "' name='IdRole[]' type='hidden' value='" + IdRole + "' />" + LibRole + "</td>" +
            "<td class='uk-text-center'><input id='IdStructure" + IdStructure + "' name='IdStructure[]' type='hidden' value='" + IdStructure + "' />" + LibStructure + "</td>" +
            "<td class='uk-text-center'><input id='manager" + IdStructure + "' name='manager[]' type='hidden' value='" + bManager + "' />" +
            "    <input type='checkbox' " + (bManager == 1 ? 'checked' : '') +" disabled/></td>" +
            "<td class='uk-text-center'><input id='date_deb" + IdStructure + "' name='date_deb[]' type='hidden' value='" + date_deb + "' />" + date_deb + "</td>" +
            "<td class='uk-text-center'><input id='date_fin" + IdStructure + "' name='date_fin[]' type='hidden' value='" + date_fin + "' />" + date_fin + "</td>" +
            "<td class='uk-text-center'><a id='Delete_User_Structure" + IdStructure + "' class='uk-button' title='Supprimer' onclick='delete_row(" + IdStructure + ")'>Suppprimer</a></td></tr>";

        $('#role').val("");
        $('#structure').val("");
        $('#manager')[0].checked = false;
        $('#date_deb').val("");
        $('#date_fin').val("");
    }


    showSellerTable()


    return true;
}

function delete_row(no)
{
    document.getElementById("row"+no+"").outerHTML="";


    showSellerTable()

    
    return true;
}

function showSellerTable(){

    var lstUserStructureTable = document.getElementById('lstUserStructure');

    if(lstUserStructureTable){
        isCallCenterRDV = false;
        var lignes = Array.from(lstUserStructureTable.rows);

        lignes.splice(0, 1);
        lignes.pop();

        lignes.forEach(function (ligne) {
            var selectElement = ligne;
            

            if(selectElement){
                var selectedValue = selectElement.firstElementChild.children[0].value;        
                if (selectedValue == 8) {
                    isCallCenterRDV = true;
                    return true;
                }
            }
        })

        var salespersonTable = document.getElementById('lstSeller');

        if (isCallCenterRDV) {
            salespersonTable.classList.remove('uk-hidden');
        } else {
            salespersonTable.classList.add('uk-hidden');
        }

    }
}

function add_seller(){
    var IdSeller = $('#salesperson_id').val();
    var LibSeller = $('#salesperson_id option:selected').text();

    if(IdSeller > 0) {
        var table = document.getElementById("lstSeller");
        var table_len = (table.rows.length) - 1;
        var row = table.insertRow(table_len).outerHTML = "<tr id='row" + IdSeller + "' class='item'>" +
            "<td class='uk-text-center'><input id='IdSeller" + IdSeller + "' name='IdSellers[]' type='hidden' value='" + IdSeller + "' />" + LibSeller + "</td>" +
            "<td class='uk-text-center'><a id='Delete_User_Structure" + IdSeller + "' class='uk-button' title='Supprimer' onclick='delete_row(" + IdSeller + "); reveal_seller(" + IdSeller + ")'>Suppprimer</a></td></tr>";

        // Cacher le vendeur sélectionnée dans le sélecteur
        $('#salesperson_id option:selected').hide();
        $('#salesperson_id').val("");
    }



    return true;
}

function reveal_seller(no){
    // Réafficher le vendeur caché dans le sélecteur
    var hiddenOption = $("#salesperson_id option[value='" + no + "'][style='display: none;']");
    hiddenOption.show()
}


function hideExistSeller(){
    table = $("#lstSeller tr");

    $('#lstSeller tbody tr.item').each(function() {
        var rowId = $(this).attr('id').replace('row', '');

        if(rowId){
            rowId = rowId.replace('row', '')

            var lastSelect = $('#lstSeller tbody tr.item:last select');
            var option = lastSelect.find('option[value="'+ rowId +'"]');
            
            $(option).hide();
        }
        
    })
}






