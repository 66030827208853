/**
 * Created by florent.benoit on 23/11/2016.
 */

$(document).ready(function() {

    $('#btnDownload').click(function () {
        $('#submitDownload').click();
    });

    $('#btnExportContratBrut').click(function () {
        $('#submitExportBrut').click();
    });

    $('.load-comment').hover(function() {
        $(this).find("div:first-child").show();
        }, function() {
            $(this).find("div:first-child").hide();
        }
    );

    $("#mytable #checkall").click(function () {
        if ($("#mytable #checkall").is(':checked')) {
            $("#mytable input[type=checkbox]").each(function () {
                $(this).prop("checked", true);
            });

        } else {
            $("#mytable input[type=checkbox]").each(function () {
                $(this).prop("checked", false);
            });
        }
    });

    $("[data-toggle=tooltip]").tooltip();

    $('#btnDownloadBsInvalid').click(function() {
        $("#invalid_BS").table2excel({
            exclude: ".noprint",
            name: "Bs Invalide",
            filename: "Bs Invalide",
            fileext: ".csv",
            exclude_links: true
        });
    });

    $(".BtnConfModalBSInvalid").click(function(){
        bs_id = $(this).data('bsid');
        $('#ModalDeleteDefinitif').attr("href", "/bs/delete/" + bs_id + "/1" );
        $('#ModalDeleteNonDefinitif').attr("href", "/bs/delete/" + bs_id + "/0");

        $('#ModalBSInvalid').modal('show');
    });

    // Hide the Modal
    $(".ModalBSInvalidClose").click(function(){
        $("#ModalBSInvalid").modal("hide");
    });

    $('#btnDownload').click(function () {
        $('#submitDownload').click();
    });

    $('#deletePhoto').click(function () {
        // Créer l'élément input
        var inputElement = $('<input id="photo_rdv"  type="file" name="photo" value="">');
        // Ajouter l'élément input à la div avec l'id "divPhoto"
        $('#divNewPhoto').append(inputElement);

        $('#divPhoto').hide();
        $('#getPhoto').val(0);
        console.log("Bah alors ?")
    });

    $('#showPhoto').click(function () {
        console.log("")
        var divPhoto = $('#showPhoto');
    
        // Vérifier la hauteur actuelle de la div
        var currentHeight = divPhoto.height();

        console.log("currentHeight = ", currentHeight)


        // Obtenir la hauteur totale de la fenêtre en pixels
        var viewportHeight = $(window).height();
        console.log("viewportHeight = ", viewportHeight);

        viewportHeight_by5 = viewportHeight / 5;

        viewportHeightPx = viewportHeight + "px"
        viewportHeight_by5px = viewportHeight_by5 + "px"


        if (currentHeight === viewportHeight) {
            $('#showPhoto').css({
                "height": viewportHeight_by5px
            });
        }else {
            $('#showPhoto').css({
                "height": viewportHeight
            });
        }
    });

    
    checkStatusLinkedElement();

    // SI status_id == 38(client absent) || 39 Non eligible || 40(Non éligible)
    $("#status_id").on("change", function() {
        checkStatusLinkedElement();
    })
});

function checkStatusLinkedElement(){
    elementSelected =  $("#status_id").val()

    if(elementSelected == 40 || elementSelected == 41 || elementSelected == 42){
        $("#photoElement").show();
    }else {
        $("#photoElement").hide();
    }

    if( elementSelected == 39 ){
        $("#changeDateRdv").show();
    }else {
        $("#changeDateRdv").hide();
    }
}


function showAvailableSeller(availableSellers){
    $("#salesperson_id option").each(function() {
        var optionValue = $(this).val();

        if(optionValue){
            if( availableSellers.includes( parseInt(optionValue) ) ){
                $(this).show();
            }else {
                $(this).hide();
            }
        }else {
            $(this).prop("selected", "true");
        }
    })
}



function checkAvailabilitySeller(){

    var form = document.getElementById('frmEditBs');

    // trouver un endroit ou récupérer id user
    user_id = form.getAttribute('data-userId');
    seller_id = $("#salesperson_id").val()
    rdv_date = $('#rdv_date').val()
    rdv_heure = $('#rdv_heure').val()
    
    if(rdv_heure == undefined){
        rdv_date = $('#change_rdv_date').val()
        rdv_heure = $('#change_rdv_heure').val()
    }


    if(rdv_date && rdv_heure){    rdv_heure_min = parseInt(rdv_heure.substring(0, 2)) - 1;
    rdv_heure_max = parseInt(rdv_heure.substring(0, 2)) + 1;

    rdv_heure_min = (rdv_heure_min > 23) ? 0 : rdv_heure_min;
    rdv_heure_max = (rdv_heure_max < 0) ? 23 : rdv_heure_max;

    rdv_heure_min = (rdv_heure_min < 10) ? '0' + rdv_heure_min : rdv_heure_min;
    rdv_heure_max = (rdv_heure_max < 10) ? '0' + rdv_heure_max : rdv_heure_max;

    rdv_heure_min = rdv_heure_min + ':' +  rdv_heure.substring(3, 5) + ':00';
    rdv_heure_max = rdv_heure_max + ':' +  rdv_heure.substring(3, 5) + ':00';

    bsId = 0;
    if(form){
        bsId = form.getAttribute('data-bsId');
    }

    console.log({
        'bsId:' :bsId,
        'user_id :' : user_id,
        'seller_id:' : seller_id,
        'rdv_date:' : rdv_date,
        'rdv_heure:' : rdv_heure,
        'rdv_heure_min:' : rdv_heure_min,
        'rdv_heure_max:' : rdv_heure_max
    })
    
    $.ajax({
        url: '/bs/sellers/getAvailability',
        type: 'GET',
        dataType: 'json',
        data: {
            bsId: bsId,
            user_id : user_id,
            seller_id: seller_id,
            rdv_date: rdv_date,
            rdv_heure: rdv_heure,
            rdv_heure_min: rdv_heure_min,
            rdv_heure_max: rdv_heure_max
        },
        success: function(response) {
            showAvailableSeller(response)
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.error("Status: " + textStatus);
            console.error("Error: " + errorThrown);
            console.error("Response: " + jqXHR.responseText);
        },            
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true
    });   } 
}


function add_options(){

    // Récupérer ID de l'option
    // Récupérer le text de l'option
    var IdOption = $('#options_id').val();
    var LibOption = $('#options_id option:selected').text();
    var QteOption = $('#quantite').val();

    if(IdOption > 0) {
        var table = document.getElementById("lstOptionsHomeSecurity");
        var table_len = (table.rows.length) - 1;
        
        var row = table.insertRow(table_len).outerHTML = `
        <tr id="row` + IdOption + `" class="item">
            <td td="" class="uk-text-center">
            `+ LibOption + `
            </td>
            <td class="uk-text-center">` + QteOption + `</td>
            <td class='uk-text-center'><a id='Delete_Option"` + IdOption + `"' class='uk-button' title='Supprimer' onclick='delete_row(` + IdOption + `); reveal_option("` + IdOption + `");'>Suppprimer</a></td></tr>
	    </tr>
        `;

        // Cacher le vendeur sélectionnée dans le sélecteur
        $('#options_id option:selected').hide();
        $('#options_id').val("");
        $('#quantite').val("");
    }
}


function reveal_option(no){
    // Réafficher l'option caché dans le sélecteur
    var hiddenOption = $("#options_id option[value='" + no + "'][style='display: none;']");
    hiddenOption.show()
}

function delete_row(no){
    document.getElementById("row"+no+"").outerHTML="";
    return true;
}